var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hideSuccessMessage
    ? _c(
        "div",
        {
          staticClass: "EmailSubscription-container",
          class: _vm.getCustomDisplay,
        },
        [
          _vm.desktopImage && _vm.desktopImage.cloudinaryId
            ? _c("CloudinaryImage", {
                staticClass: "EmailSubscription-image DesktopImage",
                attrs: {
                  id: _vm.desktopImage.cloudinaryId,
                  alt: _vm.desktopImage.alt,
                  "cloudinary-name": _vm.desktopImage.cloudinaryName,
                  thumbnail: _vm.desktopImage.thumbnail,
                  breakpoints: [575, 768],
                  transformation: { crop: "fill" },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.mobileImage && _vm.mobileImage.cloudinaryId
            ? _c("CloudinaryImage", {
                staticClass: "EmailSubscription-image MobileImage",
                attrs: {
                  id: _vm.mobileImage.cloudinaryId,
                  alt: _vm.mobileImage.alt,
                  "cloudinary-name": _vm.mobileImage.cloudinaryName,
                  thumbnail: _vm.mobileImage.thumbnail,
                  breakpoints: [575, 768],
                  transformation: { crop: "fill" },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "EmailSubscription-text" }, [
            _c("div", { staticClass: "EmailSubscription-titleDisplay" }, [
              _vm.getPatternTitle
                ? _c(
                    "p",
                    {
                      staticClass: "hb-font-heading-3 EmailSubscription-title",
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.getPatternTitle) + "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "hb-font-text-regular EmailSubscription-body" },
                [_vm._v("\n        " + _vm._s(_vm.getBodyProp) + "\n      ")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "EmailSubscription-submitForm" }, [
              _c(
                "form",
                { on: { submit: _vm.submitEmail } },
                [
                  _c("div", { staticClass: "EmailTitle" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.emailTitle) + "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "EmailInput" },
                    [
                      _c(
                        "b-field",
                        { staticClass: "EmailInput-wrap" },
                        [
                          _c("b-input", {
                            staticClass:
                              "row is-two-fifths-tablet is-paddingless",
                            attrs: {
                              id: "email-subscription",
                              placeholder: _vm.getInputplaceholder,
                              "validation-message": _vm.validationMessage,
                              type: "email",
                            },
                            model: {
                              value: _vm.email,
                              callback: function ($$v) {
                                _vm.email = $$v
                              },
                              expression: "email",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("b-field", { staticClass: "EmailInputButton-wrap" }, [
                        _c(
                          "a",
                          { staticClass: "control" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass:
                                  "is-primary is-filled EmailSubscription-button",
                                attrs: { "native-type": "submit" },
                              },
                              [_vm._v(_vm._s(_vm.getCtabuttontext))]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-field",
                    {
                      staticClass:
                        "columns is-marginless EmailSubscription-field mt-4",
                    },
                    [
                      _vm.getEnableAgeRestriction
                        ? _c("div", [
                            _c("label", { attrs: { for: "age-tick-check" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.checkedAgeConfirmation,
                                    expression: "checkedAgeConfirmation",
                                  },
                                ],
                                attrs: {
                                  id: "age-tick-check",
                                  type: "checkbox",
                                  "validation-message":
                                    _vm.canShowAgeConfirmationError,
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.checkedAgeConfirmation
                                  )
                                    ? _vm._i(_vm.checkedAgeConfirmation, null) >
                                      -1
                                    : _vm.checkedAgeConfirmation,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a = _vm.checkedAgeConfirmation,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.checkedAgeConfirmation =
                                              $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.checkedAgeConfirmation = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.checkedAgeConfirmation = $$c
                                      }
                                    },
                                    _vm.handleAgeCheckboxChange,
                                  ],
                                },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.confirmAgeTickMessage) +
                                  "\n            "
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.showAgeConfirmationError
                              ? _c("p", { staticClass: "help is-danger" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.confirmAgeErrorMessage) +
                                      "\n            "
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showConsentManagerError
                        ? _c("p", { staticClass: "help is-danger" }, [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.updateConsentErrorMessage
                                ),
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "hb-font-small-regular EmailSubscription-tagline",
                    domProps: { innerHTML: _vm._s(_vm.getTagline) },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      )
    : _c(
        "div",
        {
          staticClass:
            "EmailSubscription-container EmailSubscription-container--success",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "p-8-tablet px-4-mobile py-6-mobile is-flex-grow-1 mr-8",
            },
            [
              _c(
                "p",
                {
                  staticClass: "hb-font-heading-1 EmailSubscription-title mb-6",
                },
                [_vm._v("\n      " + _vm._s(_vm.getSuccesstitle) + "\n    ")]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "hb-font-text-regular EmailSubscription-body mb-0",
                },
                [_vm._v("\n      " + _vm._s(_vm.getSuccessbody) + "\n    ")]
              ),
            ]
          ),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }