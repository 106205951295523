<template>
  <div
    v-if="hideSuccessMessage"
    class="EmailSubscription-container"
    :class="getCustomDisplay"
  >
    <CloudinaryImage
      v-if="desktopImage && desktopImage.cloudinaryId"
      :id="desktopImage.cloudinaryId"
      class="EmailSubscription-image DesktopImage"
      :alt="desktopImage.alt"
      :cloudinary-name="desktopImage.cloudinaryName"
      :thumbnail="desktopImage.thumbnail"
      :breakpoints="[575, 768]"
      :transformation="{ crop: 'fill' }"
    />
    <CloudinaryImage
      v-if="mobileImage && mobileImage.cloudinaryId"
      :id="mobileImage.cloudinaryId"
      class="EmailSubscription-image MobileImage"
      :alt="mobileImage.alt"
      :cloudinary-name="mobileImage.cloudinaryName"
      :thumbnail="mobileImage.thumbnail"
      :breakpoints="[575, 768]"
      :transformation="{ crop: 'fill' }"
    />
    <div class="EmailSubscription-text">
      <div class="EmailSubscription-titleDisplay">
        <p
          v-if="getPatternTitle"
          class="hb-font-heading-3 EmailSubscription-title"
        >
          {{ getPatternTitle }}
        </p>
        <p class="hb-font-text-regular EmailSubscription-body">
          {{ getBodyProp }}
        </p>
      </div>
      <div class="EmailSubscription-submitForm">
        <form @submit="submitEmail">
          <div class="EmailTitle">
            {{ emailTitle }}
          </div>
          <div class="EmailInput">
            <b-field class="EmailInput-wrap">
              <b-input
                id="email-subscription"
                v-model="email"
                class="row is-two-fifths-tablet is-paddingless"
                :placeholder="getInputplaceholder"
                :validation-message="validationMessage"
                type="email"
              />
            </b-field>
            <b-field class="EmailInputButton-wrap">
              <a
                class="control"
              >
                <b-button
                  class="is-primary is-filled EmailSubscription-button"
                  native-type="submit"
                >{{ getCtabuttontext }}</b-button>
              </a>
            </b-field>
          </div>
          <b-field
            class="columns is-marginless EmailSubscription-field mt-4"
          >
            <div v-if="getEnableAgeRestriction">
              <label for="age-tick-check">
                <input
                  id="age-tick-check"
                  v-model="checkedAgeConfirmation"
                  type="checkbox"
                  :validation-message="canShowAgeConfirmationError"
                  @change="handleAgeCheckboxChange"
                > {{ confirmAgeTickMessage }}
              </label>
              <p
                v-if="showAgeConfirmationError"
                class="help is-danger"
              >
                {{ confirmAgeErrorMessage }}
              </p>
            </div>
            <p
              v-if="showConsentManagerError"
              class="help is-danger"
            >
              <span v-html="updateConsentErrorMessage" />
            </p>
          </b-field>
          <p
            class="hb-font-small-regular EmailSubscription-tagline"
            v-html="getTagline"
          />
        </form>
      </div>
    </div>
  </div>
  <div
    v-else
    class="EmailSubscription-container EmailSubscription-container--success"
  >
    <div class="p-8-tablet px-4-mobile py-6-mobile is-flex-grow-1 mr-8">
      <p class="hb-font-heading-1 EmailSubscription-title mb-6">
        {{ getSuccesstitle }}
      </p>
      <p class="hb-font-text-regular EmailSubscription-body mb-0">
        {{ getSuccessbody }}
      </p>
    </div>
  </div>
</template>

<script>
import TrackingProvider from 'Shared/components/tracking/providers/segment-provider'
import axios from 'axios'

export default {
  name: 'EmailSubscription',

  /**
   * Every property specified here must be also specified in EmailPopup component
   * @see shared/site/patterns/vue/components/EmailSubscription.vue
   */
  props: {
    backgroundColor: {
      type: String,
      required: true
    },
    confirmAgeTickMessage: {
      type: String,
      required: true
    },
    confirmAgeErrorMessage: {
      type: String,
      required: true
    },
    containerType: {
      type: String,
      required: false
    },
    updateConsentErrorMessage: {
      type: String,
      required: true
    },
    desktopImage: {
      type: Object,
      required: false
    },
    mobileImage: {
      type: Object,
      required: false
    },
    validationMessage: String,
    emailTitle: String,
    moduleData: Object,
    displayPopup: Boolean,
    enableAgeRestriction: Boolean
  },

  data () {
    return {
      email: '',
      hideSuccessMessage: true,
      checkedAgeConfirmation: false,
      canShowAgeConfirmationError: false,
      showConsentManagerError: false,
      trackingProvider: new TrackingProvider()
    }
  },

  computed: {
    showAgeConfirmationError () {
      return this.canShowAgeConfirmationError && !this.checkedAgeConfirmation
    },

    isModuleContentExists () {
      return this.moduleData && this.moduleData.content
    },

    getCustomDisplay () {
      return this.containerType && !this.displayPopup ? 'EmailCustomDisplay' : 'EmailDefaultDisplay'
    },

    getPatternTitle () {
      return this.isModuleContentExists ? this.moduleData.content.patterntitle : null
    },

    getBodyProp () {
      return this.isModuleContentExists ? this.moduleData.content.body : null
    },

    getInputplaceholder () {
      return this.isModuleContentExists ? this.moduleData.content.inputplaceholder : null
    },

    getCtabuttontext () {
      return this.isModuleContentExists ? this.moduleData.content.ctabuttontext : null
    },

    getTagline () {
      return this.isModuleContentExists ? this.moduleData.content.tagline : null
    },

    getSuccesstitle () {
      return this.isModuleContentExists ? this.moduleData.content.successtitle : null
    },

    getSuccessbody () {
      return this.isModuleContentExists ? this.moduleData.content.successbody : null
    },

    getEnableAgeRestriction () {
      return this.isModuleContentExists && this.moduleData.content && this.moduleData.content.enableagerestriction === 'true'
    }
  },

  methods: {
    submitEmail (event) {
      this.triggerAnalyticsEvent('button tap', 'CTA button clicked', 'Newsletter sign up', 'subscribe to newsletter')
      event.preventDefault()
      if (!this.email) {
        return
      }
      if (!window.analytics) {
        return
      }
      if (this.getEnableAgeRestriction === true && !this.checkedAgeConfirmation) {
        this.canShowAgeConfirmationError = true
        return
      }

      if (!this.areMarketingConsentPreferencesEnabled()) {
        this.showConsentManagerError = true
        return
      }
      this.trackingProvider
        .getAnalyticsReadyPromise()
        .then(this.identifyAnonymousUser)
      this.triggerAnalyticsEvent('newsletter subscription success', 'Newsletter sign up successful', 'Newsletter sign up', 'successfully subscribed to newsletter')
      this.callLeadCapture(window.analytics.user().anonymousId(), this.email, this.moduleData.content.segmentname)
    },

    handleAgeCheckboxChange (event) {
      event.preventDefault()
      if (this.checkedAgeConfirmation) {
        this.canShowAgeConfirmationError = false
      }
    },

    areMarketingConsentPreferencesEnabled () {
      if (window.OneTrust) {
        return window.OnetrustActiveGroups.includes('C0003') && // C0003 - Functional group Optanon id
          window.OnetrustActiveGroups.includes('C0004') // C0004 - Marketing and Social Media group Optanon id
      } else {
        return true
      }
    },

    identifyAnonymousUser () {
      if (!this.email) {
        return
      }

      const anonymousId = window.analytics.user().anonymousId()

      TrackingProvider.updateUserTraits(anonymousId, {
        email: this.email,
        customSegment: this.moduleData.content.segmentname
      })

      this.hideSuccessMessage = false
      this.canShowAgeConfirmationError = false
      this.showConsentManagerError = false
    },

    triggerAnalyticsEvent (name, step, screen, eventDescription) {
      TrackingProvider.trackEvent(name, {
        description: eventDescription,
        step,
        screen
      })
    },

    callLeadCapture (userId, email, customSegment) {
      axios.post('/ecommerce/lead-capture', {
        userId: userId,
        email: email,
        customSegment: customSegment
      })
    }
  }
}

</script>

<style lang="scss">

.EmailSubscription-button {
  margin-left: 12px !important;
  padding: 11px 24px !important;
  text-decoration: none;
  width: max-content;

  @media (max-width: 767px) {
    margin-left: 0 !important;
    width: -webkit-fill-available; // sass-lint:disable-line no-vendor-prefixes
  }
}

.EmailSubscription-field {
  flex-wrap: wrap;

  p {
    width: 100%;
  }
}

.EmailTitle {
  font-weight: bold;
  padding: 0 0 1rem;
}

.EmailInput {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: start;

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
  }
}

.EmailInput-wrap {
  flex-basis: auto;
  @media (max-width: 767px) {
    flex-basis: 0;
  }

  @media (min-width: 768px) {
    width: calc(100% - 121px);
  }
}

.EmailInputButton-wrap {
  height: 4.8rem;
}

.Autotext a:not(.Button),
.Autotext a strong {
  text-decoration: none;
}

</style>
